
import './App.css';

import Wave from 'react-wavify'

import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from './logo.png'
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faViber } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import { faViadeo} from '@fortawesome/free-brands-svg-icons'
import { faEnvira} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot} from '@fortawesome/free-solid-svg-icons';


const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">
   
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Star Heating</span>
              <img
                className="h-20 w-auto"
                src={logo}                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
       
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Star Heating<span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
  
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        
          <div className="text-center">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
           Social Media  Star Heating Systems
            </h1>
            <br />
            <br />

            <a
            target="_blank"
                href="https://www.facebook.com/profile.php?id=100076010847430"
                className="rounded-md bg-bgpage px-20 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-bgred focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgred"
                style={{color: "#ffffff",}}
             >
             <FontAwesomeIcon icon={faFacebook} style={{color: "#ffffff",}} /> 
             &nbsp;&nbsp;&nbsp;Facebook
                           </a>
              <br />
              <br />

              <a
              target="_blank"
                href="https://www.instagram.com/star.heating/"
                className="rounded-md bg-bgpage px-20 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-bgred focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgred"
                style={{color: "#ffffff",}} 
           >

<FontAwesomeIcon icon={faInstagram} style={{color: "#ffffff",}} /> 
             &nbsp;&nbsp;&nbsp;
               Instagram
              </a>
              <br />
              <br />
              <a
              target="_blank"
   href="https://api.whatsapp.com/send/?phone=9647504495297&text&type=phone_number&app_absent=0"
                   className="rounded-md bg-bgpage px-20 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-bgred focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgred"
         
                style={{color: "#ffffff",}} 
         >
                <FontAwesomeIcon icon={faWhatsapp} style={{color: "#ffffff",}} /> 
             &nbsp;&nbsp;&nbsp;
               WhatsApp
              </a>

              <br />
              <br />
              <a
              target="_blank"
                className="rounded-md bg-bgpage px-20 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-bgred focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgred"
                style={{color: "#ffffff",}}  
             href="viber://add?number=9647704495297"
             >
              
              <FontAwesomeIcon icon={faViber} style={{color: "#ffffff",}} /> 
             &nbsp;&nbsp;
             <span className='px-4'>  Viber </span>
              </a>


              <br />
              <br />
              <a
              target="_blank"
href="mailto:STAR.HEATING76@GMAIL.COM"
                   className="rounded-md bg-bgpage px-20 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-bgred focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgred"
                  
                style={{color: "#ffffff",}} 
         >  &nbsp;&nbsp;

                <FontAwesomeIcon icon={faEnvelope} style={{color: "#ffffff",}} /> 
             &nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;
               Email&nbsp;&nbsp;  &nbsp;
              </a>
              <br />
              <br />
      

          <a
          target="_blank"
href="https://www.google.com/maps?q=36.1880831,44.0180956&hl=en-IQ&gl=iq&entry=gps&lucs=,47071704&g_ep=CAISBjYuNzQuMhgAINeCAyoJLDQ3MDcxNzA0QgJJUQ%3D%3D&g_st=ifm"
                   className="rounded-md bg-bgpage px-20 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-bgred focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgred"
                  
                style={{color: "#ffffff",}} 
         >  &nbsp;&nbsp;

<FontAwesomeIcon icon={faLocationDot} />  
           &nbsp;&nbsp;
               Location&nbsp;&nbsp;  &nbsp;
              </a>


          </div>
        
      
        </div>
        
      </div>
      <p className='static mt-5 text-white  text-xs text-center items-center justify-between'  >Website Create by <a href="https://wa.me/+9647831158434" target="_blank" className='text-gray-300 font-bold'>Root Group</a></p>   

      <Wave mask="url(#mask)" fill="#1cbfde" >
  <defs>

 <linearGradient id="gradient" gradientTransform="rotate(20)">
      <stop offset="0" stopColor="white" />
      <stop offset="0.7" stopColor="black" />
    </linearGradient>
    <mask id="mask">
      <rect x="0" y="0" width="4000" height="200" fill="url(#gradient)"  />
    </mask>
  </defs>
</Wave>
    </div>
  )
}
